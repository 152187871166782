<template>
  <v-app>
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="8">
          <v-card>
            <v-card-title>
              Simple Vuetify Form for Encrypting the Message
            </v-card-title>
            <v-card-text>
              <v-form @submit.prevent="handleSubmit">
                <v-row>
                  <v-col>
                    <v-text-field
                        v-model="message"
                        label="Message to encrypt"
                        required
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                        v-model="passwordEncrypt"
                        label="Password"
                        required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-btn type="submit" color="primary">Submit</v-btn>
              </v-form>
            </v-card-text>
            <v-card-text v-if="idOfInputText">
              {{ idOfInputText }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="8">
          <v-card>
            <v-card-title>
              Simple Vuetify Form for Decrypting the Message
            </v-card-title>
            <v-card-text>
              <v-form @submit.prevent="handleSubmitDecrypting">
                <v-row>
                  <v-col cols="3">
                    <v-select
                        v-model="messageID"
                        :items="items"
                        label="Message ID"
                        required
                    ></v-select>
                  </v-col>
                  <v-col cols="9">
                    <v-text-field
                        v-model="passwordDecrypt"
                        label="Password"
                        required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-btn type="submit" color="primary">Submit</v-btn>
              </v-form>
            </v-card-text>
            <v-card-text v-if="decryptedMessage">
              {{ decryptedMessage }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-card>
          <v-card-title>
            Delete all Messages
          </v-card-title>
          <v-card-text>
            <v-btn @click="resetDatabase">Delete all Messages</v-btn>
          </v-card-text>
          <v-card-text v-if="resetMessage">
            {{ resetMessage }}
          </v-card-text>
        </v-card>
      </v-row>
      <v-row justify="center">
        <v-col cols="12" md="8">
          <v-card>
            <v-card-title>
              Message IDs
            </v-card-title>
            <v-list :items="items">
              <template v-for="(item, index) in items" :key="item">
                <div :class="['pa-2', index % 2 === 0 ? 'bg-grey-lighten-5' : '']">
                  {{ item }}
                </div>
              </template>
            </v-list>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import axios from 'axios';

export default {

  name: 'HelloWorld',
  data() {
    return {
      message: '',
      passwordEncrypt: "",
      idOfInputText: undefined,
      errorMessageEncrypt: "",
      messageID: "",
      passwordDecrypt: "",
      decryptedMessage: "",
      errorMessageDecrypt: "",
      resetMessage: "",
      items: []
    };
  },
  methods: {
    async handleSubmit() {
      try {
        const response = await axios.post(`/api/encrypt`, {
          message: this.message,
          password: this.passwordEncrypt
        });
        this.idOfInputText = "This message has the ID: " + response.data;
        this.message = '';
        this.passwordEncrypt = '';
        await this.getMessageIds();
      } catch (error) {
        if (error.response && error.response.data) {
          this.idOfInputText = error.response.data.message;
        } else {
          this.idOfInputText = 'An error occurred while processing your request.';
        }
      }
    },
    async getMessageIds() {
      const response = await axios.get(`/api/ids`);
      this.items = response.data;
    },
    async handleSubmitDecrypting() {
      try {
        const response = await axios.post(`/api/decrypt`, {
          id: this.messageID,
          password: this.passwordDecrypt
        });
        this.decryptedMessage = "This message is: " + response.data;
        this.id = '';
        this.passwordDecrypt = '';
      } catch (error) {
        if (error.response && error.response.data) {
          this.decryptedMessage = error.response.data.message;
        } else {
          this.decryptedMessage = 'An error occurred while processing your request.';
        }
      }
    },
    async resetDatabase() {
      try {
        const response = await axios.get('/api/reset');
        if (response.status === 200) {
          this.resetMessage = 'Database has been reset successfully!';
        } else {
          this.resetMessage = 'Failed to reset the database.';
        }
      } catch (error) {
        this.resetMessage = 'Error: ' + error.resetMessage;
      }
      await this.getMessageIds();
    }
  },
  async mounted() {
    await this.getMessageIds();
  }
};
</script>

<style>
.text-left td {
  text-align: left !important;
}
</style>

